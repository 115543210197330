import React from 'react';
import { Box, H1 } from '@vp/swan';
import { ProductCard, ReadOnlyReview } from '@99designs/feature-design-services-brief';
import { __ } from '@99designs/i18n';
import { ShellInner } from '@99designs/swan-wizard';
import { BriefFragment } from '../../lib';

interface BriefProps {
    brief: BriefFragment;
}

export const Brief = ({ brief }: BriefProps) => {
    return (
        <ShellInner>
            <Box mb={10} mx={5} mt={5}>
                <H1 fontSkin="title-headline">{__('Your design project details')}</H1>
                {(brief.product || brief.request?.expertService) && (
                    <ProductCard
                        product={brief.product ?? undefined}
                        expertService={brief.request?.expertService}
                        withProductModal
                        removeBottomMargin
                    />
                )}
                <ReadOnlyReview isCollaborationMode={false} brief={brief} />
            </Box>
        </ShellInner>
    );
};
