import { ReactNode, useMemo } from 'react';
import { BoundedContent, Box, Link, Typography } from '@vp/swan';
import { bugtracker } from '@99designs/design-services-common';
import { NotFound, PageLayout } from '@99designs/design-services-layouts';
import { __, getCurrentCulture } from '@99designs/i18n';
import { PageTracking } from '@99designs/tracking';

export function NotFoundErrorPage() {
    return (
        <ErrorWrapper pageName="Not Found">
            <NotFound useImg={true} />
        </ErrorWrapper>
    );
}

export function PermissionErrorPage() {
    return (
        <ErrorWrapper pageName="Permission Denied">
            <GenericError title={__('You do not have permission to access this page.')} />
        </ErrorWrapper>
    );
}

export function ErrorPage({ error }: { error: Error }) {
    bugtracker.notify(error);

    return (
        <ErrorWrapper pageName="Error">
            <GenericError title={__('Boo. Something went wrong.')} />
        </ErrorWrapper>
    );
}

function GenericError({ title }: { title: string }) {
    return (
        <BoundedContent>
            <Box marginTop={10} marginBottom={'7'} textAlign="center">
                <Box marginTop={8}>
                    <Typography fontSize="x3large" fontWeight="bold">
                        {title}
                    </Typography>
                </Box>
                <Box marginTop={8} marginBottom={10}>
                    <Link href="/">{__('Get back to our homepage')}</Link>
                </Box>
            </Box>
        </BoundedContent>
    );
}

function ErrorWrapper({ pageName, children }: { pageName: string; children: ReactNode }) {
    const locale = useMemo(() => getCurrentCulture(), []);

    const tracking: PageTracking = {
        pageName: `Matching:${pageName}`,
        pageSection: 'My Account',
        pageStage: 'My Account',
        pageDept: 'Design Services',
    };

    return (
        <PageLayout locale={locale} breadcrumbs={[]} pageTracking={tracking}>
            {children}
        </PageLayout>
    );
}
