import { useTracking } from '@vp/react-tracking';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { BoundedContent } from '@vp/swan';
import { __ } from '@99designs/i18n';
import { Brief, useOpportunityContext } from '@99designs/matching';

export function Overview() {
    const { tracking } = useTracking();

    const { brief } = useOpportunityContext();

    useEffect(() => {
        // The equivalent event for 99designs is "Job details page viewed"

        tracking.page('Design Services:Matching:Overview', {
            pageName: 'Design Services:Matching:Overview',
            pageSection: 'Design Services',
            pageStage: 'Design',
        });
    }, [tracking]);

    return (
        <BoundedContent>
            <Helmet>
                <title>{__('Vistaprint | Design Services Project Brief')}</title>
            </Helmet>
            <Brief brief={brief} />
        </BoundedContent>
    );
}
